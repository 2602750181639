define("ember-timer/templates/components/timer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JSPxe7At",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"days\",\"hours\",\"minutes\",\"seconds\",\"momentDuration\"],[[24,[\"days\"]],[24,[\"hours\"]],[24,[\"minutes\"]],[24,[\"seconds\"]],[24,[\"duration\"]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-timer/templates/components/timer.hbs"
    }
  });

  _exports.default = _default;
});