define("ember-w-pack/templates/components/w-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YnDedIyN",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showComponent\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"t-align-c mt-3 bold\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"grey-2\"],[12,\"disabled\",[29,[[28,\"if\",[[24,[\"disabledPrevious\"]],\"disabled\"],null]]]],[3,\"action\",[[23,0,[]],\"previousPage\"]],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"class\",\"mr-2\"],[10,\"src\",\"https://minio.codingblocks.com/img/lt-gradient.svg\"],[10,\"alt\",\"\"],[8],[9],[0,\" Prev\\n  \"],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"white mx-4\"],[8],[0,\"\\n    \"],[1,[23,0,[\"start\"]],false],[0,\"-\"],[1,[23,0,[\"end\"]],false],[0,\" of \"],[1,[23,0,[\"numberOfTotalResults\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \\n  \"],[7,\"button\",false],[12,\"class\",\"grey-2\"],[12,\"disabled\",[29,[[28,\"if\",[[24,[\"disabledNext\"]],\"disabled\"],null]]]],[3,\"action\",[[23,0,[]],\"nextPage\"]],[8],[0,\"\\n    Next \"],[7,\"img\",true],[10,\"class\",\"ml-2\"],[10,\"src\",\"https://minio.codingblocks.com/img/gt-gradient.svg\"],[10,\"alt\",\"\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-w-pack/templates/components/w-pagination.hbs"
    }
  });

  _exports.default = _default;
});