define("ember-w-pack/templates/components/w-tab-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cIv6CYy3",
    "block": "{\"symbols\":[\"tab\",\"index\",\"tab\",\"index\",\"@onTabChange\",\"@tabs\",\"&default\"],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"mode\"]],\"underline\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"tab-nav-underline w-100 justify-content-start t-align-c \",[22,\"tabClass\"]]]],[8],[0,\"\\n\"],[4,\"each\",[[23,6,[]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",false],[12,\"class\",[29,[\"tab px-0 mr-md-5 mr-sm-4 mr-3 font-sm bold \",[28,\"if\",[[28,\"eq\",[[23,3,[]],[24,[\"activeTab\"]]],null],\"active\",[24,[\"inactiveClass\"]]],null]]]],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],[23,5,[]],[23,3,[]]],null]]],[8],[1,[23,3,[\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[3,4]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"\"],[8],[0,\"\\n\"],[4,\"each\",[[23,6,[]]],null,{\"statements\":[[0,\"      \"],[7,\"button\",false],[12,\"class\",[29,[\" mr-3 \",[28,\"if\",[[28,\"eq\",[[23,1,[]],[24,[\"activeTab\"]]],null],\"button-solid button-orange\",\"button-dashed button-white\"],null]]]],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],[23,5,[]],[23,1,[]]],null]]],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[7,\"div\",true],[8],[0,\"\\n  \"],[14,7],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-w-pack/templates/components/w-tab-nav.hbs"
    }
  });

  _exports.default = _default;
});